import React, { useEffect } from "react"
import axios from "axios"

const Download = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const fileName = urlParams.get('fn') 
    const type = urlParams.get('type') 
    if(fileName){
        axios({
                url:'https://karinagrant.s3.eu-west-1.amazonaws.com/audio-download/'+type+'/'+fileName+'.mp3',
                method:'GET',
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'audio/mpeg',
                },
        })
        .then((response) => {
            const url = window.URL
            .createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName+'.mp3');
                    document.body.appendChild(link);
                    link.click();
        })
    }
}


const DownloadAudio = () => {
    useEffect(() => {
        Download()
      },)
    return (
        <>
            
        </>
    )
}

export default DownloadAudio